import React, { forwardRef } from "react"
import {
  GestureResponderEvent,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native"
import {
  BrowserRouter,
  Link as RRLink,
  LinkProps,
  Redirect,
  Route,
  StaticRouter,
  Switch,
} from "react-router-dom"

export { Redirect, Route, Switch, RRLink }

interface LinkAnchorProps extends TouchableOpacityProps {
  navigate: () => void
  target?: string
}

function isModifiedEvent(event: any) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

/**
 * references:
 * - https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
 */
const LinkAnchor = forwardRef<TouchableOpacity, LinkAnchorProps>(
  ({ navigate, onPress, ...rest }, forwardedRef) => {
    const { target } = rest

    const handlePress = (rnEvent: GestureResponderEvent) => {
      try {
        if (onPress) {
          onPress(rnEvent)
        }
      } catch (ex) {
        rnEvent.preventDefault()
        throw ex
      }

      // so filthy! haha
      const clickEvent = window.event

      if (
        !clickEvent ||
        (!clickEvent.defaultPrevented &&
        (!target || target === "_self") && // let browser handle "target=_blank" etc.
          !isModifiedEvent(clickEvent))
      ) {
        navigate()
        rnEvent.preventDefault()
        // rnw cancels the window.event
      }
    }

    return (
      // @ts-ignore onClick not a legit view prop
      <TouchableOpacity
        onPress={handlePress}
        accessibilityTraits="link"
        accessibilityRole="link"
        ref={forwardedRef}
        {...rest}
      />
    )
  }
)

export const Link = ({ ...rest }: LinkProps) => {
  return <RRLink component={LinkAnchor} {...rest} />
}

// @ts-ignore
export const Router = process.browser ? BrowserRouter : StaticRouter
