import React from "react"
import { Platform, View } from "react-native"

/**
 * Provides a <form> so password managers work
 */
export default function Form({ children }: { children: React.ReactNode }) {
  if (Platform.OS === "web") {
    // onSubmit should not be called, but just to be safe
    return (
      <form
        onSubmit={e => e.preventDefault()}
        style={{
          alignItems: "stretch",
          boxSizing: "border-box",
          display: "flex",
          flexBasis: "auto",
          flexDirection: "column",
          flexShrink: 0,
        }}
      >
        {children}
      </form>
    )
  }

  return <View>{children}</View>
}
