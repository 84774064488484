import React from "react"
import { Text, StyleSheet, ViewStyle, TextStyle } from "react-native"
import { Link } from "../routing/Router"
import { colors } from "../theme"

export default function LinkButton({
  title,
  to,
  ...rest
}: {
  title: string
  to: string
  testID?: string
  accessibilityLabel?: string
}) {
  return (
    <Link style={styles.linkButton} to={to} {...rest}>
      <Text style={styles.linkText}>{title}</Text>
    </Link>
  )
}

export type LinkButtonProps = React.ComponentProps<typeof LinkButton>

// https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/Button/index.js
// https://github.com/facebook/react-native/blob/master/Libraries/Components/Button.js
const styles = StyleSheet.create({
  linkButton: {
    backgroundColor: colors.primary,
    borderRadius: 2,
  } as ViewStyle,
  linkText: {
    color: colors.white,
    fontWeight: "500",
    padding: 8,
    textAlign: "center",
    textTransform: "uppercase",
  } as TextStyle,
})
