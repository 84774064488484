import React from "react"
import { colors } from "../theme"
import { TextStyle, TextProps, StyleSheet, Text } from "react-native"

// TODO: why isn't children on textprops?
const ErrorText = (props: TextProps & { children: React.ReactNode }) => (
  <Text {...props} style={styles.ErrorText} />
)

const styles = StyleSheet.create({
  ErrorText: {
    color: colors.red,
  } as TextStyle,
})

export default ErrorText
