import React from "react"
import { Text } from "react-native"

const Time = ({
  time,
  dateToCompare = new Date().toISOString(),
}: {
  time: string
  dateToCompare?: string
}) => {
  return (
    <Text numberOfLines={1}>
      {timeAgoInWords(new Date(time), new Date(dateToCompare))}
    </Text>
  )
}

const timeAgoInWords = (date: Date, dateToCompare: Date) => {
  const twoMinutesAgo = new Date(dateToCompare.getTime() - 2 * 60 * 1000)

  if (date > twoMinutesAgo) {
    return "Now"
  }

  // TODO would return stuff like "2 hours ago"
  // if (isAfter(date, subDays(dateToCompare, 1))) {
  //   return distanceInWords(dateToCompare, date, { addSuffix: true }).replace(
  //     /about\s/i,
  //     ""
  //   )
  // }

  if (date.getFullYear() !== dateToCompare.getFullYear()) {
    try {
      return date.toLocaleDateString(undefined, {
        year: "numeric", // "2-digit",
        month: "short",
        day: "numeric",
      })
    } catch (e) {
      return basicFormat(date)
    }
  }

  try {
    return date.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    })
  } catch (e) {
    return basicFormat(date)
  }
}

function basicFormat(date: Date) {
  return [
    date.getFullYear(),
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
  ].join("-")
}

export default Time
