import React, { useMemo } from "react"
import { ActivityIndicator, StyleSheet, View, ViewStyle } from "react-native"
import {
  DataProvider,
  LayoutProvider,
  RecyclerListView,
} from "recyclerlistview"
import {
  RecipeCardWithUser,
  RecipeCardWithoutUser,
} from "../components/RecipeCard"
import {
  RecipeList as RecipeListType,
  RecipeList_nodes,
} from "../generated/types/RecipeList"
import { useDimensionsBasic } from "../hooks/useDimensions"
import { space } from "../theme"
import isNonNull from "../util/isNonNull"
import { ScrollContextHelper } from "../util/ScrollContextHelper"
import { Loading } from "./Loading"
import { NarrowContainer } from "./ScreenContainer"

const RecipeList = ({
  recipes,
  isFetching,
  onLoadMore,
  contextProvider: scrollContext,
  RecipeCardComponent = RecipeCardWithUser,
}: {
  recipes: RecipeListType | null
  isFetching: boolean
  onLoadMore: () => void
  contextProvider: ScrollContextHelper
  RecipeCardComponent?: typeof RecipeCardWithUser | typeof RecipeCardWithoutUser
}) => {
  const { width } = useDimensionsBasic()

  const nodes = useMemo(() => {
    return recipes ? (recipes.nodes || []).filter(isNonNull) : []
  }, [recipes])

  const dataProvider = useMemo(
    () =>
      new DataProvider(
        (r1: RecipeList_nodes, r2: RecipeList_nodes) => r1.id !== r2.id
      ).cloneWithRows(nodes),
    [nodes]
  )

  const layoutProvider = useMemo(
    () =>
      new LayoutProvider(
        () => 0,
        (_, dim) => {
          dim.width = width
          dim.height = RecipeCardComponent.height + 1
        }
      ),
    [width, RecipeCardComponent]
  )

  if (nodes.length === 0) {
    if (isFetching)
      return (
        <View style={styles.activity}>
          <ActivityIndicator />
        </View>
      )
    return null
  }

  return (
    <RecyclerListView
      useWindowScroll={true}
      layoutProvider={layoutProvider}
      dataProvider={dataProvider}
      rowRenderer={(_, data) => (
        <NarrowContainer>
          <RecipeCardComponent recipe={data} />
        </NarrowContainer>
      )}
      onEndReached={onLoadMore}
      renderFooter={() => <Loading isPlaceholder={!isFetching} />}
      contextProvider={scrollContext}
    />
  )
}

const styles = StyleSheet.create({
  RecipeCardContainer: {
    marginBottom: StyleSheet.hairlineWidth,
  } as ViewStyle,
  activity: {
    paddingTop: space[3],
    paddingBottom: space[3],
  } as ViewStyle,
  spacer: {
    height: 25,
  } as ViewStyle,
})

export default React.memo(RecipeList)
