import { useViewer } from "./useViewer"
import { useHistory } from "react-router"
import { useEffect } from "react"
import { loginPath } from "../routing/paths"

export default function useAuthenticatedRoute() {
  const { viewer, loading } = useViewer()
  const history = useHistory()
  useEffect(() => {
    if (!loading && !viewer) {
      history.push(loginPath())
    }
  }, [history, loading, viewer])
}
