import React from "react"
import { ActivityIndicator, StyleSheet, ViewStyle, View } from "react-native"
import { space } from "../theme"
import ScreenContainer from "./ScreenContainer"

export const Loading = ({
  isPlaceholder = false,
  style,
}: {
  isPlaceholder?: boolean
  style?: ViewStyle
}) => (
  <View style={[styles.root, style]}>
    {!isPlaceholder && <ActivityIndicator />}
  </View>
)
export const LoadingScreen = () => (
  <ScreenContainer>
    <Loading />
  </ScreenContainer>
)

const styles = StyleSheet.create({
  root: {
    paddingTop: space[3],
    paddingBottom: space[3],
    height: 40,
  } as ViewStyle,
})
