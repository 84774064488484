/**
 * removes the www from a domain
 *
 * ```
 * removeWww("www.cool.biz") // "cool.biz"
 * ```
 *
 * @param domain - host that may start with `www.`
 */
export function removeWww(domain: string) {
  return domain.replace(/^www\./, "")
}
