import gql from "graphql-tag"
import { imageFragment } from "./image.query"
import { urlFragment } from "./url.query"

export const recipeCardFragment = gql`
  fragment RecipeCardFragment on Recipe {
    id
    slug
    name
    instructions
    createdAt
    urlVerbatim
    shortId
    ingredientCount
    user {
      id
      handle
    }
    url {
      ...Url
    }
    images {
      ...Image
    }
  }
  ${imageFragment}
  ${urlFragment}
`

export const recipeListFragment = gql`
  fragment RecipeList on RecipeConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      ...RecipeCardFragment
    }
  }
  ${recipeCardFragment}
`
