import React from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { space } from "../theme"

type spaceScale = 0 | 1 | 2 | 3 | 4

interface Props {
  children: React.ReactNode
  mb?: spaceScale
  mt?: spaceScale
  p?: spaceScale
  testID?: string
  style?: ViewStyle
}

const Box = ({ mb, mt, p, style, ...rest }: Props) => (
  <View
    style={[
      style,
      styles.Box,
      styles[`mb${mb}`],
      styles[`mt${mt}`],
      styles[`p${p}`],
    ]}
    {...rest}
  />
)

interface Styles {
  [x: string]: ViewStyle
}

const styles = StyleSheet.create<Styles>({
  Box: {},
  mb0: { marginBottom: space[0] },
  mb1: { marginBottom: space[1] },
  mb2: { marginBottom: space[2] },
  mb3: { marginBottom: space[3] },
  mb4: { marginBottom: space[4] },
  mt0: { marginTop: space[0] },
  mt1: { marginTop: space[1] },
  mt2: { marginTop: space[2] },
  mt3: { marginTop: space[3] },
  mt4: { marginTop: space[4] },
  p0: { padding: space[0] },
  p1: { padding: space[1] },
  p2: { padding: space[2] },
  p3: { padding: space[3] },
  p4: { padding: space[4] },
})

export default Box
