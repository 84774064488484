import React from "react"
import { Button, ButtonProps } from "react-native"
import { colors } from "../theme"

export const ButtonPrimary = (props: ButtonProps) => (
  <Button color={colors.primary} {...props} />
)

export const ButtonOutline = (props: ButtonProps) => (
  <Button color={colors.black} {...props} />
)
