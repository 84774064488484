import React from "react"
import { createElement, StyleSheet, View, ViewProps } from "react-native"

interface Props extends ViewProps {
  ratio: number
  children: React.ReactNode
}

// https://github.com/necolas/react-native-web/issues/427#issuecomment-352474031
const AspectRatio = ({ ratio, children, onLayout, style }: Props) => {
  const percentage = 100 / ratio

  return (
    <View
      onLayout={onLayout}
      style={[styles.root, style]}
      testID="AspectRatio.web"
    >
      {createElement("div", {
        style: [styles.ratio, { paddingBottom: `${percentage}%` }],
      })}
      {createElement("div", {
        children,
        style: styles.content,
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    // @ts-ignore
    display: "block",
    overflow: "hidden",
  },
  ratio: {
    // @ts-ignore
    display: "block",
    width: "100%",
  },
  content: {
    bottom: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
})

export default AspectRatio
