import React from "react"
import {
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  TextStyle,
} from "react-native"
import { colors, fontSizes, space } from "../theme"

const TextInput = React.forwardRef<RNTextInput, RNTextInputProps>(
  (props, ref) => <RNTextInput {...props} ref={ref} style={styles.TextInput} />
)
export default TextInput

const styles = StyleSheet.create({
  TextInput: {
    flexGrow: 1,
    padding: space[2],
    borderStyle: "solid",
    borderColor: colors.gray,
    borderWidth: StyleSheet.hairlineWidth,
    // backgroundColor: colors.white,
    fontSize: fontSizes.base,
  } as TextStyle,
  TextInputFocus: {
    borderColor: colors.primary,
    shadowColor: colors.primary,
    shadowRadius: 4,
  } as TextStyle,
})
