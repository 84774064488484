import { useApolloClient, useMutation } from "@apollo/react-hooks"
import React, { useEffect, useState } from "react"
import { Platform, StyleSheet, View } from "react-native"
import { useHistory, useLocation } from "react-router"
import { ButtonOutline } from "../components/Button"
import { NavLink, NavLinkCircle, NavLinkPrimary } from "../components/NavLink"
import QueryError from "../components/QueryError"
import {
  deleteSessionToken as deleteSessionTokenType,
  deleteSessionTokenVariables,
} from "../generated/types/deleteSessionToken"
import { useHistoryEntries } from "../hooks/useHistoryEntries"
import { useSession } from "../hooks/useSession"
import { useViewer } from "../hooks/useViewer"
import { deleteSessionTokenQuery } from "../queries/sessionToken.query"
import {
  bookmarkNewPath,
  loginPath,
  rootPath,
  searchPath,
  settingsPath,
  friendsPath,
} from "../routing/paths"
import * as theme from "../theme"

const NavLinkGroup = ({ children }: { children?: React.ReactNode }) => (
  <View style={styles.navLinkGroup}>{children}</View>
)

export default function NavigationContainer() {
  const [isProfileExpanded, setIsProfileExpanded] = useState(false)
  const { viewer, loading } = useViewer()

  const sessionContext = useSession()
  const history = useHistory()
  const client = useApolloClient()
  const { pathname } = useLocation()

  const [deleteSessionToken, { data: logoutData, error }] = useMutation<
    deleteSessionTokenType,
    deleteSessionTokenVariables
  >(deleteSessionTokenQuery, {
    variables: {
      input: {
        cookie: sessionContext.authenticationStrategy === "cookies",
      },
    },
  })

  useEffect(() => {
    setIsProfileExpanded(false)
  }, [pathname])

  useEffect(() => {
    if (!logoutData) return

    if (logoutData.deleteSessionToken.errors.length === 0) {
      if (sessionContext.authenticationStrategy === "bearer") {
        sessionContext.setSessionToken(null)
      }
      client.resetStore()

      history.push(loginPath())
    }
  }, [client, logoutData, history, sessionContext])

  if (logoutData && logoutData.deleteSessionToken.errors.length > 0) {
    throw new Error("invariant: delete session token errors")
  }

  return (
    <>
      <View style={styles.navigationContainer}>
        {viewer ? (
          <>
            <NavLinkGroup>
              <View>
                <View>
                  <NavLinkCircle
                    accessibilityLabel="Profile Settings"
                    onPress={() => setIsProfileExpanded(!isProfileExpanded)}
                    character={viewer.handle[0]}
                    style={{ marginRight: theme.space[2] }}
                  />
                </View>
                {isProfileExpanded && (
                  <View
                    style={{
                      position: "absolute",
                      backgroundColor: theme.colors.white,
                      borderColor: theme.colors.black,
                      borderWidth: StyleSheet.hairlineWidth,
                      padding: theme.space[2],
                      marginTop: theme.space[1] + 1,
                      top: "100%",
                      zIndex: 1,
                      width: 200,
                    }}
                  >
                    <NavLink to={settingsPath()}>Settings</NavLink>
                    <ButtonOutline
                      title="Log out"
                      onPress={() => deleteSessionToken()}
                    />
                    {error && <QueryError error={error} />}
                  </View>
                )}
              </View>

              <NavLink to={rootPath()}>Home</NavLink>
              <NavLink to={friendsPath()}>Friends</NavLink>
              <NavLink to={searchPath()}>Search</NavLink>
            </NavLinkGroup>
            <NavLinkGroup>
              <NavLinkPrimary
                to={bookmarkNewPath()}
                testID="new-bookmark-button"
                title="+ Save Recipe"
                smallTitle="+ Save"
                accessibilityLabel="Save recipe"
              />
            </NavLinkGroup>
          </>
        ) : loading ? (
          <NavLinkGroup>
            <View style={styles.spacer} />
          </NavLinkGroup>
        ) : (
          <React.Fragment>
            <NavLinkGroup>
              <NavLink to={rootPath()}>Home</NavLink>
              <NavLink to={searchPath()}>Search</NavLink>
            </NavLinkGroup>
            <NavLink to={loginPath()}>Sign in</NavLink>
          </React.Fragment>
        )}
      </View>
      {Platform.OS === "web" && (
        <View style={styles.navigationContainerSpacer} />
      )}
    </>
  )
}

export function NavigationBack() {
  const history = useHistory()
  const historyEntries = useHistoryEntries()
  const canGoBack = historyEntries.index > 0
  const handleBack = () => {
    canGoBack ? history.goBack() : history.push(rootPath())
  }

  return (
    <>
      <View style={styles.navigationContainer}>
        <NavLinkGroup>
          <NavLinkCircle
            accessibilityLabel="Go back"
            onPress={handleBack}
            character="←"
            style={{ marginRight: theme.space[2] }}
          />
        </NavLinkGroup>
      </View>
      {Platform.OS === "web" && (
        <View style={styles.navigationContainerSpacer} />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  navigationContainer: {
    zIndex: 1,
    backgroundColor: theme.colors.white,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.border,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.space[1],
    paddingRight: theme.space[1],
    paddingTop: Platform.OS === "web" ? 0 : 50,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    // maxWidth: "100%",
    // width: "100%",
    // overflow: "hidden",
    // display: "none"
  },
  navLinkGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: theme.space[1],
    paddingRight: theme.space[1],
  },
  spacer: { height: 40 },
  navigationContainerSpacer: {
    height: 41,
    backgroundColor: theme.colors.silver,
  },
})
