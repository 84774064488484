import { useEffect } from "react"
import { Platform } from "react-native"

export default function useScrollToTop(perform = true) {
  useEffect(() => {
    if (Platform.OS !== "web") return //tbd
    if (!perform) return

    window.scrollTo(0, 0)
  }, [perform])
}
