import gql from "graphql-tag"

const viewerQuery = gql`
  query Viewer {
    viewer {
      id
      handle
      email
    }
  }
`

export default viewerQuery
