import React from "react"
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native"
import { useLocation } from "react-router"
import { useDimensions } from "../hooks/useDimensions"
import { Link } from "../routing/Router"
import * as theme from "../theme"
import LinkButton, { LinkButtonProps } from "./LinkButton"

export const NavLink = ({
  children,
  to,
}: {
  children: React.ReactNode
  to: string
}) => {
  const isActive = useActive(to)

  return (
    <Link style={[styles.NavLink, isActive && styles.NavLinkActive]} to={to}>
      <Text style={styles.NavLinkText}>{children}</Text>
    </Link>
  )
}

export const NavLinkPrimary = ({
  smallTitle,
  title,
  ...props
}: LinkButtonProps & { smallTitle: string }) => {
  const dimensions = useDimensions()
  return (
    <View style={styles.NavLinkPrimary}>
      <LinkButton
        title={dimensions.window.width > 600 ? title : smallTitle}
        {...props}
      />
    </View>
  )
}

const useActive = (to: string) => {
  const location = useLocation()
  return to === location.pathname
}

export const NavLinkCircle = ({
  character,
  style,
  ...props
}: {
  character: string
} & TouchableOpacityProps) => {
  return (
    <TouchableOpacity style={[style, styles.navLinkCircle]} {...props}>
      <View style={styles.NavLinkCircle}>
        <Text style={styles.NavLinkCircleText}>{character.toUpperCase()}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create<{ [x: string]: TextStyle | ViewStyle }>({
  NavLink: {
    padding: theme.space[2],
    borderTopWidth: 3,
    borderBottomWidth: 3,
    borderStyle: "solid",
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
  } as ViewStyle,
  NavLinkText: {
    color: theme.colors.primary,
    fontSize: theme.fontSizes.base,
  } as TextStyle,
  NavLinkPrimary: {},
  NavLinkActive: {
    borderBottomColor: theme.colors.primary,
  },
  NavLinkCircle: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.colors.primaryBackground,
    justifyContent: "space-around",
    alignItems: "center",
  } as ViewStyle,
  NavLinkCircleText: {
    color: theme.colors.white,
  },
  navLinkCircle: {
    padding: 5,
  },
})
