import * as Sentry from "@sentry/browser"
import React, { Component } from "react"
import { SafeAreaView, StyleSheet, Text, View, ViewStyle } from "react-native"
import Box from "../components/Box"
import ErrorText from "../components/ErrorText"
import LinkButton from "../components/LinkButton"
import { rootPath } from "../routing/paths"
import { ExternalLink } from "../components/ExternalLink"
import { isApolloError } from "apollo-client"

const PROD = process.env.NODE_ENV === "production"

/**
 * TODO: refactor to consider
 * https://www.npmjs.com/package/react-error-boundary
 *
 * basic error handling example shown on sentry docs:
 * https://docs.sentry.io/clients/javascript/integrations/react/
 */
export default class ErrorReporter extends Component<{ inRouter?: boolean }> {
  state = {
    error: null as null | unknown,
    isExpected: false,
  }

  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    const isExpected = isErrorExpected(error)

    this.setState({ error, isExpected })

    if (!isExpected) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          // this is from sentry's docs so assume it works?
          // @ts-ignore
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  render() {
    const { error, isExpected } = this.state
    const { inRouter, children } = this.props

    if (error) {
      return (
        <View style={styles.container}>
          <SafeAreaView>
            <Box mb={3} p={3}>
              <ErrorText>An unexpected error occured:</ErrorText>
              <ErrorText>{errorMessage(error)}</ErrorText>
            </Box>
            <Box mb={3} p={3}>
              {isExpected ? (
                <Text>Please check your connection and try again.</Text>
              ) : (
                <Text>This is likely a bug with braise 🐛</Text>
              )}
            </Box>

            {inRouter ? (
              <LinkButton to={rootPath()} title="Go home" />
            ) : (
              <Box p={3}>
                {/* TODO: works in web, what to do in RN? */}
                <ExternalLink href={rootPath()} target={"_self"}>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    Go Home
                  </Text>
                </ExternalLink>
              </Box>
            )}
            {!PROD && (
              <Box mb={3} p={3}>
                <Text>
                  ℹ You are in development, see the console for details
                </Text>
              </Box>
            )}
          </SafeAreaView>
        </View>
      )
    } else {
      //when there's not an error, render children untouched
      return children || null
    }
  }
}

function errorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message || error.toString()
  }
  return `${error}`
}

const IGNORED_APOLLO_NETWORK_ERRORS = ["Failed to fetch", "cancelled"]

function isErrorExpected(error: unknown): boolean {
  if (!(error instanceof Error)) return false

  if (isApolloError(error)) {
    if (error.networkError) {
      const { message } = error.networkError
      return IGNORED_APOLLO_NETWORK_ERRORS.includes(message)
    }
  }

  return false
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    marginHorizontal: "auto",
    marginVertical: 20,
  } as ViewStyle,
})
