import * as colors from "./theme/colors"
import * as fontWeights from "./theme/fontWeights"
import * as fontSizes from "./theme/fontSizes"

export const space: [0, 4, 8, 16, 32] = [0, 4, 8, 16, 32]

const widths = {
  narrow: 600,
  normal: 900,
}

export { colors, fontWeights, fontSizes, widths }
