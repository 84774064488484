import { ContextProvider } from "recyclerlistview"
import React, { createContext, useState, useContext, useMemo } from "react"
import { useHistory } from "react-router"
import useScrollToTop from "../hooks/useScrollToTop"

/**
 * From recycler list view
 */
export class ScrollContextHelper extends ContextProvider {
  _uniqueKey: string
  _contextStore: { [x: string]: string }

  constructor(uniqueKey: string) {
    super()
    this._uniqueKey = uniqueKey
    this._contextStore = {}
  }

  getUniqueKey() {
    return this._uniqueKey
  }

  save(key: string, value: string) {
    this._contextStore[key] = value
  }

  get(key: string) {
    return this._contextStore[key]
  }

  remove(key: string) {
    delete this._contextStore[key]
  }
}

interface ScrollMap {
  [key: string]: ScrollContextHelper
}

const ScrollContext = createContext<null | {
  scrollMap: ScrollMap
  setScrollMap: React.Dispatch<React.SetStateAction<ScrollMap>>
}>(null)

const { Provider } = ScrollContext

/**
 * Provides the ScrollMap
 */
export function ScrollContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [scrollMap, setScrollMap] = useState<ScrollMap>({})
  const value = useMemo(() => ({ scrollMap, setScrollMap }), [scrollMap])
  return <Provider value={value}>{children}</Provider>
}

/**
 * Gets the RecyclerListView ContextProvider for the given key
 *
 * todo: consider breaking this down to reduce conditionals
 */
function useScrollContext(key: string, restoreScroll = false) {
  const ctx = useContext(ScrollContext)

  if (!ctx)
    throw new Error("useScrollMap must be used inside a <ScrollProvider>")

  const { scrollMap, setScrollMap } = ctx

  const scrollContextHelper = useMemo(() => new ScrollContextHelper(key), [key])

  if (restoreScroll && key in scrollMap) {
    return scrollMap[key]
  }

  if (scrollMap[key] !== scrollContextHelper) {
    setScrollMap(map => ({ ...map, [key]: scrollContextHelper }))
  }

  return scrollContextHelper
}

export function useScrollContextAlways(key: string) {
  return useScrollContext(key, true)
}

export function useScrollContextOnPop(key: string) {
  const history = useHistory()
  const isPop = history.action === "POP"

  // !isPop = normal navigation -> scroll to top
  // isPop  = back button -> restore scroll
  useScrollToTop(!isPop)
  return useScrollContext(key, isPop)
}
