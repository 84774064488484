import React from "react"
import { StyleSheet, Text, TextStyle, View } from "react-native"
import { Url } from "../generated/types/Url"
import { colors } from "../theme"
import { removeWww } from "../util/url"
import { ExternalLink } from "./ExternalLink"

export default function UrlLink({
  urlVerbatim,
  url,
  textStyle,
}: {
  urlVerbatim: string
  url: Url
  textStyle?: TextStyle
}) {
  return (
    <ExternalLink href={urlVerbatim}>
      <View>
        <Text numberOfLines={1} style={[styles.textRoot, textStyle]}>
          <Text style={styles.domain}>{removeWww(url.domain)}</Text>
          <Text>{url.pathname}</Text>
        </Text>
      </View>
    </ExternalLink>
  )
}

const styles = StyleSheet.create({
  // styling textRoot so that ellipses are gray
  textRoot: {
    color: colors.gray,
  },
  domain: {
    color: colors.black,
  },
})
