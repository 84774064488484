// https://github.com/ReactTraining/react-router/issues/5437
// https://github.com/ReactTraining/react-router/blob/v4.3.1/packages/react-router-dom/modules/Link.js
import React from "react"
import { Linking, Platform, View, TouchableHighlightProps } from "react-native"

export interface ExternalLinkProps extends TouchableHighlightProps {
  href: string
  children: React.ReactNode
  target?: "_blank" | "_self" | undefined
}

export const ExternalLink = ({
  href,
  target = "_blank",
  ...rest
}: ExternalLinkProps) => (
  // @ts-ignore
  <View
    href={href}
    accessibilityRole="link"
    target={target}
    onPress={() => {
      if (Platform.OS === "web") return
      Linking.openURL(href)
    }}
    {...rest}
  />
)
