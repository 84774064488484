import React, { forwardRef } from "react"
import { Text, TextProps } from "react-native"

interface Props extends TextProps {
  recipe: { name: string | null; url: { title: string | null } | null }
}

/**
 * The recipe's name, or it's url title, or "Unnamed" in a <Text> node
 */
const RecipeName = forwardRef<Text, Props>(({ recipe, ...rest }, ref) => {
  const text = recipe.name || (recipe.url && recipe.url.title) || "Unnamed"
  return (
    <Text {...rest} ref={ref}>
      {text}
    </Text>
  )
})

export default RecipeName
