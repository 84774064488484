import { useEffect } from "react"
import { Platform } from "react-native"

const PROD = process.env.NODE_ENV === "production"

export default function useDocumentTitle(
  title: string,
  suffix: string = " · Braise"
) {
  const devIndicator = PROD ? "" : "⌁ "
  useEffect(() => {
    if (Platform.OS !== "web") return

    document.title = `${devIndicator}${title}${suffix}`
    return () => {
      document.title = "Braise"
    }
  }, [title, suffix, devIndicator])
  return null
}
