import gql from "graphql-tag"
import { validationErrorFragment } from "./validationError.query"

export const imageFragment = gql`
  fragment Image on Image {
    id
    s3Key
    width
    height
  }
`

export const createImageQuery = gql`
  mutation createImage($input: CreateImageInput!) {
    createImage(input: $input) {
      image {
        ...Image
      }
      errors {
        ...ValidationErrorFragment
      }
    }
  }

  ${validationErrorFragment}
  ${imageFragment}
`

export const findImageQuery = gql`
  query findImage($id: ID!) {
    find(id: $id) {
      ... on Image {
        ...Image
      }
    }
  }

  ${imageFragment}
`

export const cookingNoteImageScreenQuery = gql`
  query cookingNoteImageScreen($cookingNoteId: ID!, $imageId: ID!) {
    cookingNote: find(id: $cookingNoteId) {
      ... on CookingNote {
        id
        recipe {
          id
          slug
          user {
            id
            handle
          }
        }
      }
    }

    image: find(id: $imageId) {
      ... on Image {
        ...Image
      }
    }
  }
  ${imageFragment}
`
