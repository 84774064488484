import gql from "graphql-tag"
import { recipeListFragment } from "./recipe.query"

export const homeScreenQuery = gql`
  query homeScreen($after: String, $query: String) {
    viewer {
      id
      recipes(after: $after, query: $query, first: 30) {
        ...RecipeList
      }
    }
  }
  ${recipeListFragment}
`
