import gql from "graphql-tag"
import { validationErrorFragment } from "./validationError.query"

export const createSessionTokenBearerMutation = gql`
  mutation createSessionTokenBearer($input: CreateSessionTokenInput!) {
    createSessionToken(input: $input) {
      sessionToken {
        id
      }
      errors {
        ...ValidationErrorFragment
      }
    }
  }
  ${validationErrorFragment}
`

export const createSessionTokenCookieMutation = gql`
  mutation createSessionTokenCookie($input: CreateSessionTokenInput!) {
    createSessionToken(input: $input) {
      errors {
        ...ValidationErrorFragment
      }
    }
  }
  ${validationErrorFragment}
`

export const deleteSessionTokenQuery = gql`
  mutation deleteSessionToken($input: DeleteSessionTokenInput!) {
    deleteSessionToken(input: $input) {
      errors {
        ...ValidationErrorFragment
      }
    }
  }
  ${validationErrorFragment}
`
