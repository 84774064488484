import React, { ReactNode } from "react"
import { SafeAreaView, StyleSheet, View, ViewStyle } from "react-native"
import { colors, widths } from "../theme"
import Box from "./Box"

interface Props {
  children: ReactNode
}

const ScreenContainer = ({ children }: Props) => (
  <View style={styles.Container} testID={"ScreenContainer"}>
    <View style={styles.Body}>{children}</View>
  </View>
)

export const NarrowContainer = ({
  style,
  children,
}: {
  style?: ViewStyle
  children?: React.ReactNode
}) => (
  <View style={[styles.fullWidth, style]}>
    <View style={[styles.narrow, style]}>{children}</View>
  </View>
)

export const NormalContainer = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <View style={styles.fullWidth}>
    <View style={styles.normal}>{children}</View>
  </View>
)

export const ScreenContainerBasicNarrow = ({ children, ...rest }: Props) => (
  <ScreenContainer {...rest}>
    <NarrowContainer style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <Box p={2} style={{ flex: 1 }}>
          {children}
        </Box>
      </SafeAreaView>
    </NarrowContainer>
  </ScreenContainer>
)

const styles = StyleSheet.create({
  Container: {
    width: "100%",
    flex: 1,
    backgroundColor: colors.silver,
    minHeight: "100%",
  } as ViewStyle,
  Body: {
    width: "100%",
    flex: 1,
    // paddingTop: space[3],
  } as ViewStyle,
  normal: {
    width: "100%",
    maxWidth: widths.normal,
    flex: 1,
  } as ViewStyle,
  narrow: {
    width: "100%",
    maxWidth: widths.narrow,
    flex: 1,
  } as ViewStyle,
  fullWidth: {
    width: "100%",
    alignItems: "center",
    // backgroundColor: colors.silver,
  } as ViewStyle,
})

export default ScreenContainer
