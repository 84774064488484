import { useState } from "react"

// untested!
export default function usePersistedState(
  key: string,
  initialValue: string | null
): [string | null, (value: string | null) => void] {
  const [localValue, setLocalValue] = useState(initialValue)
  const isLocalStorageAvailable = typeof localStorage !== "undefined"

  if (isLocalStorageAvailable) {
    const value = localStorage.getItem(key)
    if (value) setLocalValue(value)
  }

  const setValue = (value: string | null) => {
    setLocalValue(value)

    if (isLocalStorageAvailable) {
      if (value != null) {
        localStorage.setItem(key, value)
      } else {
        localStorage.removeItem(key)
      }
    }
  }

  return [localValue, setValue]
}
