export const black = "#000000"
export const white = "#ffffff"
export const primary = "hsla(41, 95%, 45%, 1)"
export const primaryBackground = "hsla(41, 95%, 50%, 1)"
export const gray = "#aaaaaa"
export const border = "#bbbbbb"
export const silver = "#dddddd"
// grayLight: "#dddddd",
// grayLightest: "#f9f9f9",

export const nearWhite = "#f4f4f4"
export const red = "#8b0000"
